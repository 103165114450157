<template>
  <div class="my-auto w-full mx-12 p-12">
    <div class="text-center mb-6">
      <h1>Reset password</h1>
      <p class="text-gray-500">
        Enter your email to get a password reset link.
      </p>
    </div>

    <form @submit.prevent="send" @keydown="form.onKeydown($event)">
      <alert v-if="status" color="green">
        {{ status }}
      </alert>

      <!-- Email -->
      <div class="form-group row mt-4">
        <label class="col-md-3 col-form-label text-md-right">{{ $t('email') }}</label>
        <div class="col-md-7">
          <input
            v-model="form.email"
            :class="{ 'is-invalid': form.errors.has('email') }"
            class="form-control"
            type="email"
            name="email"
            placeholder="your@email.com"
          >
          <has-error :form="form" field="email" />
        </div>
      </div>

      <!-- Submit Button -->
      <div class="form-group row">
        <div class="col-md-9 ml-md-auto">
          <button class="btn w-full">
            Reset password
          </button>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import Form from 'vform'

export default {
  layout: 'login',

  metaInfo () {
    return { title: this.$t('reset_password') }
  },

  data: () => ({
    status: '',
    form: new Form({
      email: ''
    })
  }),

  methods: {
    async send () {
      const { data } = await this.form.post(this.$apiUrl.password.email)

      this.status = data.status

      this.form.reset()
    }
  }
}
</script>
